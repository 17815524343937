<template>
  <div>
    <!-- HEADER STRONY -->
    <div class="row bg-light mt-1 ml-0">
      <div class="col-6">
        <router-link to="/addsquad">
          <button style="width: 10%" class="btn btn-info mt-1 mb-1 float-left">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-folder-plus"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.828 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91H9v1H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181L15.546 8H14.54l.265-2.91A1 1 0 0 0 13.81 4H9.828zm-2.95-1.707L7.587 3H2.19c-.24 0-.47.042-.684.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293z"
              />
              <path
                fill-rule="evenodd"
                d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
      </div>
      <div class="col-6">
        <!-- <input
          v-model="search"
          class="form-control w-50 float-right form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj instalacje"
          aria-label="Search"
        /> -->
      </div>
    </div>

    <!-- Brygady -->
        
        <div class="container border">
            <div class="row">
                <div v-for="squad in squads" :key="squad.id">

                </div>
            </div>
        </div>
    
      <!-- <installation-modal :installation="installation" ></installation-modal> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
// import InstallationModal from "../components/InstallationModal"


export default {
  data() {
    return {
      //   installation: {
      //     brand: "",
      //     model: "",
      //     plate: "",
      //     vin: "",
      //     year: "",
      //     imageUrl: "",
      //   },
      //   openForm: false,
      search: "",
      installation: "",
    };
  },
  components: {
    // "installation-modal": InstallationModal
  },

  computed: {
    ...mapState(["userProfile", "squads"]),
    // filteredInstallations() {
    //   return this.installations.filter((item) => {
    //     return item.contractor.name.toUpperCase().includes(this.search.toUpperCase());
    //   });
    // },
  },
  methods: {
       openInstallationModal(installation) {
      this.$modal.show("installation-modal");
      this.installation = installation;
    },
  }
};
</script>
<style>
.customer {
    background-color:rgba(0, 123, 255,0.1);
}
.company {
    background-color: rgba(23, 162, 184,0.1);
}
</style>